import { checkPermissions } from '@helpers/permissions'
import { useSelector } from 'react-redux'

const DisplayIfIsAuthorized = ({
    children,
    permissionsRequired = [],
    placeholder = () => <></>,
}) => {
    const userPermissions = useSelector((state) => state.auth.permissions)

    // Check if user has permissions
    let isAuthorized = checkPermissions(userPermissions, permissionsRequired)

    return isAuthorized ? children : placeholder()
}

export default DisplayIfIsAuthorized
