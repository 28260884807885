import Flex from '@common-components/blocs/Flex'
import Grid from '@common-components/containers/Grid'

import { Typography } from 'antd'
import React, { memo } from 'react'

const Overview = ({ data }) => {
    return (
        <Grid
            template={[
                { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
            ]}
        >
            <Flex justify="between" className="overview-bloc">
                <Typography.Title level={2}>
                    <i className="fa-light fa-folder"></i>
                    Obsèques
                </Typography.Title>
                <Typography.Title level={2} strong>
                    {data?.funerals_count || '---'}
                </Typography.Title>
            </Flex>

            <Flex justify="between" className="overview-bloc">
                <Typography.Title level={2}>
                    <i className="fa-light fa-square-list"></i>
                    Activités
                </Typography.Title>
                <Typography.Title level={2} strong>
                    {data?.services_count || '---'}
                </Typography.Title>
            </Flex>
        </Grid>
    )
}

export default memo(Overview)
