import Flex from '@common-components/blocs/Flex'
import Card from '@common-components/containers/Card'
import Grid from '@common-components/containers/Grid'

import { Button, Typography, Tooltip } from 'antd'
import React from 'react'
import _ from 'lodash'
import { ORDERS_STATUSES, ORDER_TYPE } from '@common/constants'
import { useRouter } from 'next/router'
import DisplayIfIsAuthorized from '@components/permissions'

const ServicesStats = ({ data }) => {
    const { push } = useRouter()

    const permissionsByType = {
        convoy: [['core.ServiceConvoy', 'change_serviceconvoy']],
        embalming: [['core.ServiceEmbalming', 'change_serviceembalming']],
        transfer: [['core.ServiceTransfer', 'change_servicetransfer']],
        procedure: [['core.ServiceProcedure', 'change_serviceprocedure']],
    }

    return (
        <Card
            title={
                <span>
                    <i className="fa-light fa-square-list"></i> Activités
                </span>
            }
            className="services-stats"
        >
            <Grid
                template={[
                    { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                    { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                    { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                    { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                    { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                ]}
            >
                <Flex justify="end" className="services-stats-line">
                    <div className={`services-stats-col services-stats-wo-responsible-label`}>
                        <DisplayIfIsAuthorized
                            permissionsRequired={[
                                ...permissionsByType.convoy,
                                ...permissionsByType.embalming,
                                ...permissionsByType.transfer,
                                ...permissionsByType.procedure,
                            ]}
                        >
                            <Typography.Text>à affecter</Typography.Text>
                        </DisplayIfIsAuthorized>
                    </div>
                </Flex>
                {_.map(data, (service, service_key) => {
                    // Get label of service
                    const service_label = _.find(ORDER_TYPE, { key: service_key })?.label || ''
                    let service_route = _.find(ORDER_TYPE, { key: service_key })?.route || ''

                    if (service_route == 'embalming') service_route = 'embalmings'

                    return (
                        <Flex className="services-stats-line" key={service_key}>
                            <div className={`services-stats-col services-stats-label`}>
                                <Typography.Text>{service_label}</Typography.Text>
                            </div>
                            {[
                                'to_be_completed',
                                'waiting',
                                'confirmed',
                                'finished',
                                'blocking',
                            ].map((status_key) => {
                                const status_label =
                                    _.find(ORDERS_STATUSES, { value: status_key })?.label || ''

                                let tooltip_title = `${service_label} : ${service[status_key]} en ${status_label}`

                                return (
                                    <div
                                        className={`services-stats-col services-stats-bloc is-${status_key}`}
                                        key={`${service_key}-${status_key}`}
                                    >
                                        <Tooltip title={tooltip_title}>
                                            <Typography.Text strong>
                                                {service[status_key]}
                                            </Typography.Text>
                                        </Tooltip>
                                    </div>
                                )
                            })}

                            <div className={`services-stats-col services-stats-wo-responsible`}>
                                <DisplayIfIsAuthorized
                                    permissionsRequired={permissionsByType[service_key]}
                                >
                                    <Button
                                        onClick={() =>
                                            push({
                                                pathname: `/orders/${service_route}`,
                                                query: {},
                                                // service_key != 'convoy'
                                                //     ? { responsible: false }
                                                //     : { staff_completed: false },
                                            })
                                        }
                                        className={{
                                            'is-red': service?.without_responsible > 0,
                                            'is-green': service?.without_responsible === 0,
                                        }}
                                        size="small"
                                    >
                                        {service?.without_responsible}
                                        <i className="fa-light fa-chevron-right"></i>
                                    </Button>
                                </DisplayIfIsAuthorized>
                            </div>
                        </Flex>
                    )
                })}
            </Grid>
        </Card>
    )
}

export default ServicesStats
