import { adminApi } from '@redux/rtk/api'

const ApiRoutePrefix = 'admin/stats/'

const statisticalsApi = adminApi.injectEndpoints({
    endpoints: (builder) => ({
        getStatisticalsDashboard: builder.query({
            query: (params) => ({
                url: `${ApiRoutePrefix}dashboard/`,
                method: 'GET',
                params: {
                    ...(params || {}),
                },
            }),
            providesTags: (result, error) => [{ type: 'StatsDashboard' }],
        }),
    }),
    overrideExisting: true,
})

export const { useGetStatisticalsDashboardQuery } = statisticalsApi
