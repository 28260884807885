import Flex from '@common-components/blocs/Flex'
import Card from '@common-components/containers/Card'
import Grid from '@common-components/containers/Grid'
import { formatPrice } from '@helpers/index'
import { Button, Typography, Tooltip, Space, Divider } from 'antd'
import React, { memo } from 'react'
import _ from 'lodash'
import { ORDERS_STATUSES, ORDER_TYPE } from '@common/constants'
import { getDayNumber, getDayText, getMonthText } from '@common/utils/dates'
import { useRouter } from 'next/router'
import dayjs from 'dayjs'

const ServiceConvoyAvailabilitiesPlanning = ({ planning }) => {
    return (
        <div className="service-convoy-availabilities">
            <div className="calendarHeader">
                {planning?.map((day, i) => {
                    const isToday = day?.today

                    const isHoliday = !!day?.planning?.find((halfDay) => halfDay.public_holiday)

                    return (
                        <Space
                            key={i}
                            direction="vertical"
                            align="center"
                            className={[
                                isToday ? 'isToday' : '',
                                isHoliday ? 'isHoliday' : '',
                                getDayText(day?.date) === 'dimanche' ? 'isWeekEnd' : '',
                            ].join(' ')}
                        >
                            <Typography.Title level={5} children={getDayText(day?.date)} />
                            <Typography.Title level={2} strong children={getDayNumber(day?.date)} />
                            <Typography.Title level={4} strong children={getMonthText(day?.date)} />
                        </Space>
                    )
                })}
            </div>
            <div className="calendarBody-Dashboard">
                {planning?.map((day, i) => {
                    const morningData = day?.availabilities?.find(
                        (halfDay) => halfDay.am_pm === 'am'
                    )
                    const afternoonData = day?.availabilities?.find(
                        (halfDay) => halfDay.am_pm === 'pm'
                    )

                    return (
                        <Flex
                            key={i}
                            direction="column"
                            align="center"
                            fullWidth
                            className={[' calendarDay'].join('')}
                        >
                            <Flex
                                direction="column"
                                className="calendarMorning"
                                align="start"
                                fullWidth
                            >
                                <div
                                    className={`service-convoy-availabilities-block is-${morningData?.occupancy_rate}`}
                                >
                                    {morningData?.convoy_count}
                                </div>
                            </Flex>

                            <Divider />
                            <Flex
                                direction="column"
                                className="calendarAfternoon"
                                align="start"
                                fullWidth
                            >
                                <div
                                    className={`service-convoy-availabilities-block is-${afternoonData?.occupancy_rate}`}
                                >
                                    {afternoonData?.convoy_count}
                                </div>
                            </Flex>
                        </Flex>
                    )
                })}
            </div>
        </div>
    )
}

const ServiceConvoyAvailabilitiesStats = ({ data }) => {
    return (
        <Card title="Convois - Taux d’occupation" className="services-stats">
            <ServiceConvoyAvailabilitiesPlanning planning={data} />
        </Card>
    )
}

export default ServiceConvoyAvailabilitiesStats
