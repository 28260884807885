import Grid from '@common-components/containers/Grid'
import { useGetStatisticalsDashboardQuery } from '@redux/features/statisticals'
import { useDispatch } from 'react-redux'
import React from 'react'
import _ from 'lodash'
import {
    Overview,
    ServicesStats,
    ServiceConvoyAvailabilitiesStats,
} from '@components/statisticals/dashboard'
import { POLLING_INTERVAL } from '@common/constants'

const Dashboard = () => {
    const dispatch = useDispatch()

    const { data: statisticals } = useGetStatisticalsDashboardQuery(
        {},
        {
            refetchOnMountOrArgChange: true,
            pollingInterval: POLLING_INTERVAL,
        }
    )

    return (
        <Grid
            template={[
                { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
            ]}
            className="dashboard-statisticals-index"
        >
            <Grid
                template={[
                    { xs: 24, sm: 24, md: 24, lg: 7, xl: 7 },
                    { xs: 24, sm: 24, md: 24, lg: 17, xl: 17 },
                ]}
            >
                <Overview data={statisticals?.data?.top_data} />
                <ServicesStats data={statisticals?.data?.services} />
            </Grid>
            <Grid template={[{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24 }]}>
                <ServiceConvoyAvailabilitiesStats
                    data={statisticals?.data?.service_convoy_availabilities}
                />
            </Grid>
        </Grid>
    )
}

export default Dashboard
