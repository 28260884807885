import { DATE_FORMAT } from '@common/constants'

import dayjs from 'dayjs'
import _ from 'lodash'

import * as Sentry from '@sentry/nextjs'

/**
 * format filters to the correct format needed for API
 * @param {object} values
 * @returns {object}
 */
export const formatFilter = (values) => {
    // Define list of date filters
    const DATE_FILTERS = ['bury_date', 'start_at']

    // Define list of range filters
    const RANGE_FILTERS = ['total_gross']

    // Loop on values to format them
    const formattedValues = Object.keys(values).reduce((acc, key) => {
        // Check if the key is a date filter
        if (DATE_FILTERS.indexOf(key) !== -1 && values[key]) {
            const findedKey = DATE_FILTERS[DATE_FILTERS.indexOf(key)]
            const [start, end] = values[key]

            acc[`${findedKey}_after`] = dayjs(start).format(DATE_FORMAT.api)
            acc[`${findedKey}_before`] = dayjs(end).format(DATE_FORMAT.api)
        } else if (RANGE_FILTERS.includes(key) && values[key]) {
            const { min, max } = values[key]

            acc[`min_${key}`] = !_.isNil(min) ? min : null
            acc[`max_${key}`] = !_.isNil(max) ? max : null
        } else {
            acc[key] = values[key]
        }

        return acc
    }, {})

    return formattedValues
}
/**
 * Convert datetime to front format
 * @param {dayjs} datetime
 * @returns datetime
 */
export const formatDateToFront = (date) => {
    return dayjs(date).format(DATE_FORMAT.front)
}

export const formatAmPmToText = (halfDay) => {
    return halfDay === 'am' ? ' le matin' : "l' après-midi"
}

export const formatResponsibleToSelectOptions = (responsibles) =>
    responsibles?.map((responsible) => ({
        value: responsible.funovia_id,
        label: responsible.full_name,
    }))

export const formatAgentsToSelectOptions = (agents) =>
    agents?.map((agent) => ({
        value: agent?.id,
        label: `${agent.fullname}`,
    }))

export const formatCompaniesToSelectOptions = (companies) =>
    companies?.map((company) => ({
        value: company?.id,
        label: company?.name,
    }))

export const formatGroupsToSelectOptions = (groups) =>
    groups?.map((group) => ({
        value: group?.id,
        label: group?.name,
    }))
